import { required } from '@rsa-digital/evo-shared-components/helpers/forms/rules';
import { ValidationRules } from '@rsa-digital/evo-shared-components/helpers/forms/types';
import { graphql, useStaticQuery } from 'gatsby';

type CsDriverDetailsErrorMessages = {
  csAddDriverChoosePermDriverQuestions: {
    choose_perm_driver: {
      error_messages: {
        missing: string;
      };
    };
  };
};

const query = graphql`
  query {
    csAddDriverChoosePermDriverQuestions {
      choose_perm_driver {
        error_messages {
          missing
        }
      }
    }
  }
`;

const useDriverToReplaceRules = (): ValidationRules<{ driverToReplace: string }> => {
  const errorMessages = useStaticQuery<CsDriverDetailsErrorMessages>(query);

  return {
    driverToReplace: [
      required(
        errorMessages.csAddDriverChoosePermDriverQuestions.choose_perm_driver.error_messages.missing
      ),
    ],
  };
};

export default useDriverToReplaceRules;
