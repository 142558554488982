import RadioInput from '@rsa-digital/evo-shared-components/components/Form/RadioInput';
import useValidation from '@rsa-digital/evo-shared-components/helpers/forms/useValidation';
import { navigate } from 'gatsby';
import React from 'react';
import { useReplaceDriver } from 'state/forms/replaceDriver/action';
import { usePolicyState } from 'state/policy/state';
import { useSeatStatus } from 'state/seatStatus/action';
import FormFooter from 'components/Forms/FormFooter';
import QuestionField from 'components/QuestionField';
import { trackFieldError, trackRadioButtonClick } from 'helpers/eventTracking';
import { scrollAndTrackError } from 'helpers/forms';
import { FormPage, getFormPageRoute } from 'helpers/routingHelper';
import { DriverType } from 'types/forms';
import useDriverToReplaceQuestions from './questions';
import useDriverToReplaceRules from './validation';

const DriverToReplaceForm: React.FC = () => {
  const [seatStatus] = useSeatStatus();
  const { policy } = usePolicyState();
  const [replaceDriverState, updateReplaceDriverState] = useReplaceDriver();
  const rules = useDriverToReplaceRules();
  const questions = useDriverToReplaceQuestions();

  const handleSuccess = (): void => {
    navigate(getFormPageRoute(DriverType.TEMPORARY, FormPage.AboutTheDriver));
  };

  const options = policy?.namedDrivers
    .filter(
      (driver) =>
        !!driver.driverRef && seatStatus?.replacementReferences?.includes(driver.driverRef)
    )
    .map((driver) => ({
      name: `${driver.fullName}${driver.hirer ? ` ${questions.hirerSuffix}` : ''}`,
      value: driver.driverRef || '',
    }));

  const selectedOption = options?.find(
    (driver) => driver.value === replaceDriverState.replacedDriverRef
  );

  const { getError, validateOnSubmit } = useValidation(
    {
      driverToReplace: selectedOption?.value ?? '',
    },
    rules,
    trackFieldError
  );

  return (
    <form onSubmit={validateOnSubmit(handleSuccess, scrollAndTrackError)}>
      <QuestionField question={questions.choosePermDriver} errorText={getError('driverToReplace')}>
        <RadioInput
          id="driverToReplace"
          options={options || []}
          value={replaceDriverState.replacedDriverRef ?? undefined}
          onChange={(e) => {
            updateReplaceDriverState({
              replacedDriverRef: e.target.value,
            });
            trackRadioButtonClick('Driver to replace', e.target.value);
          }}
          data-pii-mask
        />
      </QuestionField>
      <FormFooter
        backButton={{
          onClick: () => navigate(getFormPageRoute(DriverType.TEMPORARY, FormPage.NewDriversCover)),
        }}
      />
    </form>
  );
};

export default DriverToReplaceForm;
