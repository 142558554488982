import { graphql, useStaticQuery } from 'gatsby';
import useQuestionProcessor from 'helpers/useQuestionProcessor';
import { CsQuestion } from 'types/contentStack';
import { Question } from 'types/forms';

export type DriverToReplaceQuestions = {
  choosePermDriver: Question;
  hirerSuffix: string;
};

type CsDriverToReplaceQuestions = {
  csAddDriverChoosePermDriverQuestions: {
    choose_perm_driver: CsQuestion & {
      hirer_suffix: string;
    };
  };
};

const query = graphql`
  query {
    csAddDriverChoosePermDriverQuestions {
      choose_perm_driver {
        main_details {
          question_text
          explanatory_text
          tooltip {
            body
            button_text {
              open
              close
            }
          }
          alert_text
        }
        hirer_suffix
      }
    }
  }
`;

const useDriverToReplaceQuestions = (): DriverToReplaceQuestions => {
  const csQuestions = useStaticQuery<CsDriverToReplaceQuestions>(query);
  const { processQuestion } = useQuestionProcessor();

  return {
    choosePermDriver: processQuestion(
      csQuestions.csAddDriverChoosePermDriverQuestions.choose_perm_driver
    ),
    hirerSuffix: csQuestions.csAddDriverChoosePermDriverQuestions.choose_perm_driver.hirer_suffix,
  };
};

export default useDriverToReplaceQuestions;
