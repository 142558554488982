import { graphql } from 'gatsby';
import { Location } from 'history';
import React from 'react';
import DriverToReplaceForm from 'components/Forms/DriverToReplace';
import FormHeading from 'components/Forms/FormHeading';
import FormPageWrapper from 'components/Forms/FormPageWrapper';
import Layout from 'components/Layout';
import { useFormRedirect } from 'helpers/useFormRedirect';
import { DriverType } from 'types/forms';
import { RichTextWithBottomMargin } from './styles';

type DriverToReplaceContentProps = {
  data: DriverToReplaceData;
};

type DriverToReplacePageProps = DriverToReplaceContentProps & {
  location: Location;
};

const DriverToReplacePage: React.FC<DriverToReplacePageProps> = ({ data, location }) => {
  const page = data.csAddDriverChoosePermDriver;
  useFormRedirect();
  return (
    <FormPageWrapper
      location={location}
      title={page.title}
      formPageName="driverToReplace"
      driverType={DriverType.TEMPORARY}>
      <Layout pageType="form" meta={{ title: page.title }}>
        <FormHeading text={page.heading} />
        {page.summary && <RichTextWithBottomMargin html={page.summary} />}
        <DriverToReplaceForm />
      </Layout>
    </FormPageWrapper>
  );
};

export default DriverToReplacePage;

type DriverToReplaceData = {
  csAddDriverChoosePermDriver: {
    title: string;
    heading: string;
    summary: string;
  };
};

export const query = graphql`
  query {
    csAddDriverChoosePermDriver {
      title
      heading
      summary
    }
  }
`;
